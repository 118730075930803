import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import AssociateLayout from "layouts/AssociateLayout";
import Admin from "layouts/Admin";
import ManagerLayout from "layouts/ManagerLayout";
import UserAccess from "components/Auth/UserAccess";
import UserId from "components/Auth/UserId";
import HomePage from "views/examples/HomePage";
import ThemeProvider from './theme'

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,

    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USERPOOL_ID,

    // Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_WEBCLIENT_ID,

    // Hosted SSO Domain configuration
    oauth: {
      domain: process.env.REACT_APP_APPCLIENT_DOMAIN,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      responseType: "code", // REFRESH token will only be generated when the responseType is code
    },
  },
});

function App() {
  let [userData, setUserData] = useState(null);

  useEffect(() => {
    getUser().then((userData) => setUserData(userData));
  }, []);

  function getUser() {
    console.log("4pmDebug - Check2");
    return (
      Auth.currentAuthenticatedUser()
        // .then(userData => userData)
        .then((userData) => console.log("userinfoAppJs", userData))
        .catch(() => Auth.federatedSignIn())
    );
  }
  const accessLevel = UserAccess();
  //   const accessLevel = 'Tester'

  console.log("Access Level", UserAccess(), UserId());

  if (accessLevel === "unknown") {
    return (
      <div className="App">
         <ThemeProvider>
        <Router>
          <Switch>
            <Route path="/" render={() => <HomePage />} />
            <Redirect from="/" to="/admin/managerPage" />
          </Switch>
        </Router>
        </ThemeProvider>
      </div>
    );
  } else if (accessLevel === "Admin") {
    return (
      <div className="App">
        <ThemeProvider>
        <Router>
          <Switch>
            <Route path="/admin" render={(props) => <Admin {...props} />} />
            <Route
              path="/associates"
              render={(props) => <AssociateLayout {...props} />}
            />
            <Redirect from="/" to="/admin/home" />
          </Switch>
        </Router>
        </ThemeProvider>
      </div>
    );
  } else if (accessLevel === "Tester") {
    return (
      <div className="App">
        <ThemeProvider>
        <Router>
          <Switch>
            <Route
              path="/associates"
              render={(props) => <AssociateLayout {...props} />}
            />
            <Redirect from="/" to="/associates/mainpage" />
          </Switch>
        </Router>
        </ThemeProvider>
      </div>
    );
  } else if (accessLevel === "WFM") {
    return (
      <div className="App">
        <ThemeProvider>
        <Router>
          <Switch>
            <Route
              path="/manager"
              render={(props) => <ManagerLayout {...props} />}
            />
            <Redirect from="/" to="/manager/home" />
          </Switch>
        </Router>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
