import React, { useState, useEffect } from "react";
import Linkify from "react-linkify";
import SearchBar from "material-ui-search-bar";
import "../../assets/plugins/nucleo/css/Search.css";
import { sanitize } from "dompurify";
import "../../assets/plugins/nucleo/css/styleNew.css";
import "../notificationStyles.css";
import UserSessionToken from "../../components/Auth/UserSessionToken";
import CombinationsTable from "./GCOComponents/CombinationsTable";

import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  Button,
  Table,
  Container,
  Row,
  Card,
  CardHeader,
  CardTitle
} from "reactstrap";

import Tooltip from "@mui/material/Tooltip";

// core components

import Header from "components/Headers/Header.js";
import StickyTable from "react-sticky-table-thead";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/plugins/nucleo/css/style.css";
import "../../../node_modules/react-quill/dist/quill.snow.css";
import { faChartColumn,faRectangleList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@mui/material/styles'
import { Grid, Typography,  Stack } from '@mui/material'

import { OverallTrends, ManagerWiseTrends,ManagerWiseTrendsBarChart } from '../../sections/@dashboard/app'



const FileDownload = require("js-file-download"); //this is used for report download
var FileSaver = require("file-saver"); //this is used for report download


  export default function MainPageAdmin(props) {
  const [listOfNotification, setListOfNotification] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [modalInfo, setModalInfo] = useState([]);
  const [showModal, setShowModal] = useState([]);
  const [show, setShow] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [modalInfoGraph, setModalInfoGraph] = useState([]);
  const [showGraphModal, setShowGraphModal] = useState(false);
  const [apiResponse, setApiResponse] = useState([]);
  const [emptyStartDate, setEmptyStartDate] = useState("");
  const [emptyEndDate, setEmptyEndDate] = useState("");
  const [modalInfoSummary, setModalInfoSummary] = useState([]);
  const [summary, setSummary] = useState({});
  const [counts, setCounts] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [graphData, setGraphData] = useState([]);
  const [labels1, setLabels1] = useState([]);
  const [lineGraphData, setLineGraphData] = useState([]);
  const [areaGraphData, setAreaGraphData] = useState([]);
  const [barOptions, setBarOptions] = useState([]);
  const [barSeries, setBarSeries] = useState([])
  const [barChart, setBarChart] = useState({})
  const [slot, setSlot] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(null)

    const theme = useTheme()

    //Function  call to get api response in desired format with status and count
    function getFormattedGraphData(data) {
      data = JSON.parse(JSON.stringify(data).replace(/null/g, '"noManager"'))
      console.log("Inside getFormattedGraphData: ",data)
      const dataNew = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].manager) {
          dataNew[i] = {
            manager: data[i].manager,
            unopened: data[i].status == 0 ? data[i].count : 0,
            acknowledged: data[i].status == 1 ? data[i].count : 0,
            trainingRequired: data[i].status == 2 ? data[i].count : 0,
            notApplicable: data[i].status == 3 ? data[i].count : 0
          }
        }
      }
      return dataNew
    }

    //Function call to display the report data in chart format on the dashboard
    function getConstructedNewChartData(dataNew) {
      const obj1 = {}
      dataNew.forEach((item) => {
        if (!obj1[item.manager]) {
          obj1[item.manager] = {
            unopened: -1,
            acknowledged: -1,
            trainingRequired: -1,
            notApplicable: -1
          }
          obj1[item.manager] = {
            unopened: (obj1[item.manager].unopened) != -1 ? obj1[item.manager].unopened : item.unopened,
            acknowledged: obj1[item.manager].acknowledged != -1 ? obj1[item.manager].acknowledged : item.acknowledged,
            trainingRequired: obj1[item.manager].trainingRequired != -1 ? obj1[item.manager].trainingRequired : item.trainingRequired,
            notApplicable: obj1[item.manager].notApplicable != -1 ? obj1[item.manager].notApplicable : item.notApplicable
          }
        }
        else {
          obj1[item.manager] = {
            unopened: obj1[item.manager].unopened ? obj1[item.manager].unopened : item.unopened,
            acknowledged: obj1[item.manager].acknowledged ? obj1[item.manager].acknowledged : item.acknowledged,
            trainingRequired: obj1[item.manager].trainingRequired ? obj1[item.manager].trainingRequired : item.trainingRequired,
            notApplicable: obj1[item.manager].notApplicable ? obj1[item.manager].notApplicable : item.notApplicable,
          }
        }
      })
      console.log("formatted Data obj1: " ,obj1)
      const chartLabels1 = Object.keys(obj1)
      const statusObj1 = {}
      chartLabels1.forEach((manager) => {
        Object.keys(obj1[manager]).forEach((status) => {
          statusObj1[status] = statusObj1.hasOwnProperty(status)
            ? [...statusObj1[status], obj1[manager][status]]
            : [obj1[manager][status]]
        })
      })
      console.log("formatted Data statusOj1 and chartLabels1", statusObj1 ,chartLabels1)
      const chartData = [
        {
          name: 'UnOpened',
          type: 'area',
          fill: 'gradient',
          data: statusObj1 ? statusObj1.unopened : 0,
        },
        {
          name: 'Acknowledged',
          type: 'line',
          fill: 'solid',
          data: statusObj1 ? statusObj1.acknowledged : 0,
        },
        {
          name: 'TrainingRequired',
          type: 'line',
          fill: 'solid',
          data: statusObj1 ? statusObj1.trainingRequired : 0,
        },
        {
          name: 'NotApplicable',
          type: 'line',
          fill: 'solid',
          data: statusObj1 ? statusObj1.notApplicable : 0,
        }
      ]


      const chartData1 = [
        {
          name: 'Unopened',
          type: 'bar',
          fill: 'solid',
          data: statusObj1 ? statusObj1.unopened : 0,
        },
        {
          name: 'Acknowledged',
          type: 'bar',
          fill: 'solid',
          data: statusObj1 ? statusObj1.acknowledged : 0,
        },
        {
          name: 'TrainingRequired',
          type: 'bar',
          fill: 'solid',
          data: statusObj1 ? statusObj1.trainingRequired : 0,
        },
        {
          name: 'NotApplicable',
          type: 'bar',
          fill: 'solid',
          data: statusObj1 ? statusObj1.notApplicable : 0,
        }
      ]


      let tempSeries = [{
        name: "unopened",
        data: chartData[0].data? chartData[0].data:0
      }, {
        name: "acknowledged",
        data: chartData[1].data? chartData[1].data:0
      }, {
        name: "trainingRequired",
        data: chartData[2].data? chartData[2].data:0
      },
      {
        name: "notApplicable",
        data: chartData[3].data?chartData[3].data:0
      }]



      return {
        chartLabels1,
        chartData,
        tempSeries,
        chartData1
      }
    }


  useEffect(() => {
    getListOfNotification();
  }, [date]);
  
  let defaultSendNotificationPage = ["ADECT"];
  const getListOfNotification = async () => {
    const fetchDirectory = (defaultSendNotificationPage.includes(process.env.REACT_APP_TENANT))
        ? "getAllNotificationDetails/"
        : "getAllGCONotificationDetails/";

    const result = await axios
        .get(
            process.env.REACT_APP_NOTIFIERV2_BACKEND +
            fetchDirectory +
            formatDate(date),{headers:{'Content-Type': 'application/json', Authorization: UserSessionToken()}}
        )
        .then((response) => {
          console.log("Response in Success", response.data);
          setListOfNotification(response.data);
        })
        .catch((AxiosError) => {
          console.log("Error", AxiosError);
          let errorResponse = JSON.stringify(AxiosError);
          console.log("Error res", errorResponse);
          setListOfNotification([]);
        });
    let customData = [];

    console.log("List of Notifications");
  };

  const handleCloseReportModal = () => setShowReportModal(false);
  const handleShowReportModal = () => setShowReportModal(true);

  const handleCloseGraphModal = () => setShowGraphModal();
  const handleShowGraphModal = () => setShowGraphModal(true);

  const handleSummaryClose = () => {
    setSlot(false)
    setTotalNotifications(0)
    setLabels1([])
    setGraphData([])
    setLineGraphData([])
    setAreaGraphData([])
    setBarSeries([])
    setBarChart({})
    setShowGraphModal(false);
    setShowSummaryModal(false);
    setSummary({})
    setCounts({})
    setTableData([])
  }

  const handleSummaryShow = () => setShowSummaryModal(true);

  const [search, setSearch] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  useEffect(() => {
    setFilteredNotifications(
        listOfNotification.filter(
            (item, index) =>
                item.notificationId.toString().indexOf(search) > -1 ||
                item.notification.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                item.date.toString().indexOf(search) > -1 ||
                item.teams.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
    );
  }, [search, listOfNotification]);

  const validate = () => {
    let error1, error2, error3;
    if (startDate === null) {
      error1 = "Please enter start date!!";
      setEmptyStartDate(error1);
      setEmptyEndDate("");
    } else if (endDate === null) {
      error2 = "Please enter end date!!";
      setEmptyEndDate(error2);
      setEmptyStartDate("");
    } else {
      if (startDate < endDate) error3 = "Start Date is ";
      setEmptyStartDate("");
      setEmptyStartDate("");
    }

    if (error1 || error2) {
      return false;
    } else {
      return true;
    }
  };

  const handleDownload = (e) => {
    const downloadActionByAdmin = e.target.value;
    const notificationId = modalInfo.notificationId;
    let fileName = notificationId + "_NotifierReport.xlsx";
    console.log(
        "Action and NotificatioId ",
        downloadActionByAdmin,
        notificationId
    );
    if (downloadActionByAdmin) {
      let resource =
          process.env.REACT_APP_NOTIFIERV2_BACKEND +
          "reportsByNotification/" +
          notificationId;

      //API Call
      axios
          .get(resource,{ headers: {"Authorization" : UserSessionToken()},responseType: 'arraybuffer'  })
          .then((response) => {
            // Log somewhat to show that the browser actually exposes the custom HTTP header
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader];
            const effectiveFileName =
                suggestedFileName === undefined ? fileName : suggestedFileName;
            console.log(
                `Received header [${fileNameHeader}]: ${suggestedFileName}, effective fileName: ${effectiveFileName}`
            );
            // Let the user save the file.
            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, effectiveFileName);
          })
          .catch((response) => {
            console.error(
                "Could not Download the Excel report from the backend.",
                response
            );
          });
    }
    setShow(false);
  };

  function formatDate(date) {
    var dd = date.getDate();

    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    date = mm + "-" + dd + "-" + yyyy;
    console.log(date);
    return date;
  }

  const handleDownloadReport = (e) => {
    console.log("StartDate", startDate);
    console.log("EndDate", endDate);
    console.log(e.target.value);
    let val = validate();
    console.log("Validate", val);

    if (validate()) {
      let formattedStartDate = formatDate(startDate);
      let formattedEndDate = formatDate(endDate);

      let fileName =
          "NotifierReport_From_" +
          formattedStartDate +
          "_to_" +
          formattedEndDate +
          ".xlsx";
      console.log(
          "StartDate and EndDate: ",
          formattedStartDate,
          formattedEndDate
      );

      let resource =
          process.env.REACT_APP_NOTIFIERV2_BACKEND +
          "reportsBydates/" +
          formattedStartDate +
          "/" +
          formattedEndDate;

      //API Call
      axios
          .get(resource,{ headers: {"Authorization" : UserSessionToken()},responseType: 'arraybuffer' })
          .then((response) => {
            // Log somewhat to show that the browser actually exposes the custom HTTP header
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader];
            const effectiveFileName =
                suggestedFileName === undefined ? fileName : suggestedFileName;
            console.log(
                `Received header [${fileNameHeader}]: ${suggestedFileName}, effective fileName: ${effectiveFileName}`
            );

            // Let the user save the file.
            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileDownload(blob,effectiveFileName)
          })
          .catch((response) => {
            console.error(
                "Could not Download the Excel report from the backend.",
                response
            );
          });
    }
    setShow(false);
  };

  const handleCloseReport = () => {
    setEmptyEndDate("");
    setEmptyStartDate("");
    setShowReportModal(false);
  };

    const handleCloseGraph = () => {
      setSlot(false)
      setTotalNotifications(0)
      setLabels1([])
      setGraphData([])
      setLineGraphData([])
      setAreaGraphData([])
      setBarSeries([])
      setBarChart({})
      setShowGraphModal(false);
      setSummary({})
      setCounts({})
      setTableData([])
    };

  const getSummary = async (e) => {
    let result;
    if (e !== undefined) {
      result = await axios.get(
          process.env.REACT_APP_NOTIFIERV2_BACKEND + "getSummaryByUpdate/" + e, { headers: {"Authorization" : UserSessionToken()}}
      );


      let tempGraphData = result.data.counts;
      setTotalNotifications(result.data.counts.totalNotifications)
      if (tempGraphData) {
        let chartData = [
          {
            label: 'UnOpened',
            value: tempGraphData.unopened ? tempGraphData.unopened : 0,
          },
          {
            label: 'Acknowledged',
            value: tempGraphData.acknowledged ? tempGraphData.acknowledged : 0,
          },
          {
            label: 'TrainingRequired',
            value: tempGraphData.trainingRequired ? tempGraphData.trainingRequired : 0,
          },
          {
            label: 'NotApplicable',
            value: tempGraphData.notApplicable ? tempGraphData.notApplicable : 0,
          }

        ]
        setGraphData(chartData)
      }
      else {
        let chartData = [
          {
            label: 'UnOpened',
            value: 0,
          },
          {
            label: 'Acknowledged',
            value: 0,
          },
          {
            label: 'TrainingRequired',
            value: 0,
          },
          {
            label: 'NotApplicable',
            value: 0,
          },
        ]
        setGraphData(chartData)
      }


      let customData = [];
      setSummary(result.data);
      setCounts(result.data.counts);

      let largest = Math.max(
          result.data.didNotopen.length,
          result.data.acknowledged.length,
          result.data.notApplicable.length,
          result.data.trainingRequired.length
      );
      console.log("Max length of Array--> ", largest);
      let tempTableData = [];
      for (let i = 0; i < largest; i++) {
        tempTableData.push({
          didNotOpen: result.data.didNotopen[i] || "-",
          acknowledged: result.data.acknowledged[i] || "-",
          notApplicable: result.data.notApplicable[i] || "-",
          trainingRequired: result.data.trainingRequired[i] || "-",
        });
      }
      setTableData(tempTableData);
      console.log("Temp Table", tempTableData);
      console.log("Table", tableData);
    }
  };

  //API call for Reporting Dashboard
    const getDashBoardMatric = async (e) => {
      let result;
      if (e !== undefined) {
        result = await axios.get(
          process.env.REACT_APP_NOTIFIERV2_BACKEND + "getSummaryByUpdateForAllManagers/" + e, { headers: { "Authorization": UserSessionToken() } }
        );
        let newFormattedData = getFormattedGraphData(result.data)
        let dashBoardData = getConstructedNewChartData(newFormattedData);
        if(result){
        setLabels1(dashBoardData.chartLabels1)
        setLineGraphData(dashBoardData.chartData)
        setAreaGraphData(dashBoardData.chartData1)
        setBarSeries(dashBoardData.tempSeries)
        setBarChart(dashBoardData.chartDataNew)
        }
     
      }
    };

  useEffect(
      (e) => {
        getSummary(e);
        getDashBoardMatric(e)
      },
      [handleSummaryShow]
  );
  const handleDelete = (e) => {
    const deleteActionByAdmin = e.target.value;
    const notificationId = modalInfo.notificationId;
    if (deleteActionByAdmin) {
      fetch(
          process.env.REACT_APP_NOTIFIERV2_BACKEND +
          "deleteProcessUpdate/" +
          notificationId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
              Authorization: UserSessionToken()
            },

          }
      )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            setApiResponse(result);
            if (result.message === "SUCCESSFUL") {
              swal({
                text: "You have successfully deleted the process update",
                type: "success",
              }).then((okay) => {
                if (okay) {
                  window.location.href =
                      process.env.REACT_APP_REDIRECT_URL + "admin/mainpage";
                }
              });
            }
          });
    }
  };

  const getPageCount = () => {
    console.log("data", listOfNotification);
    console.log("pagesize", pageSize);
    setPageCount(Math.ceil(listOfNotification.length / pageSize));
    console.log("PageCount", pageCount);
  };

  useEffect(() => {
    getPageCount();
  }, []);

  const rowEvents = {
    onClick: (e) => {
      console.log("event", e);
    },
  };

  const handleClick = (e) => {
    console.log("event", e);
    setModalInfo(e);
    var bl = toggleTrueFalse();
    console.log("Set Modal info ", modalInfo);
    console.log("togglevalue", bl);
  };

  const toggleTrueFalse = () => {
    setShowModal(handleShow);
  };

  //Use to open the hyperlink in new tab
  const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
  );
  const ModalContent = () => {
    console.log("List of Notification inside Modal", listOfNotification);
    return (
        <Modal
            {...props}
            size='lg'
            className='modal-fluid'
            dialogClassName='modal-90w'
            aria-labelledby='contained-modal-title-vcenter'
            show={show}
            onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title
                id='contained-modal-title-vcenter'
                class='textSizeHeader addNewStyling'
            >
              <h1>Process Update:</h1>
            </Modal.Title>
            <hr />
          </Modal.Header>
          <Modal.Body
              // style={{ "overflow": "hidden", "word-break": "break-all" }}
          >
            <Linkify properties={{ target: "_blank" }}>
              {/* <div
              dangerouslySetInnerHTML={{
                __html: modalInfo.notification,
              }}
            /> */}
              <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(modalInfo.notification),
                  }}
                  className='ql-editor'
              ></div>
            </Linkify>
            <div
                class='combinationsTable'
                hidden={
                  !process.env.REACT_APP_TENANT.includes("GCO")
                  // && !process.env.REACT_APP_TENANT.includes("APPSTORE")
                }
            >
              <br></br> <br></br> <br></br> <br></br>
              <br></br>
              <h2>Sent to: </h2>
              <CombinationsTable {...modalInfo} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color='primary' value='Download' onClick={handleDownload}>
              Download
            </Button>
            <Button color='danger' value='Delete Update' onClick={handleDelete}>
              Delete Update
            </Button>
            <Button color='secondary' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    );
  };

  const ModalContentReport = () => {
    return (
        <Modal size="sm" show={showReportModal} onHide={handleCloseReportModal}>
          <Modal.Header closeButton>
            <Modal.Title
                id="contained-modal-title-vcenter"
                class="textSizeHeader addNewStyling"
            >
              {" "}
              Download Your Report Here
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  isClearable
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()}
                  minDate={new Date(2020, 0, 1)}
                  placeholderText=" Start Date.."
              />
            </Row>
            <div style={{ color: "red" }}>{emptyStartDate}</div>
            <br></br>
            <Row>
              <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  isClearable
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()}
                  minDate={new Date(2020, 0, 1)}
                  placeholderText=" End Date.."
              />
            </Row>
            <div style={{ color: "red" }}>{emptyEndDate}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
                color="primary"
                value="Download"
                onClick={handleDownloadReport}
            >
              Download
            </Button>
            <Button
                color="danger"
                value="Delete Update"
                onClick={handleCloseReport}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    );
  };

    const ModalContentGraph = () => {
      return (
        // <Modal className="modal-fluid-new" show={showGraphModal} onHide={handleCloseGraphModal}>
        <Modal
            {...props}
            size='xl'
            className='modal-fluid-new'
            dialogClassName='modal-90w'
            aria-labelledby='contained-modal-title-vcenter'
            show={showGraphModal} 
            onHide={handleCloseGraphModal}
        >
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" sx={{ mb: 5 }}>
              </Typography>
            </Stack>
            <Grid container spacing={4} sx={{ m: 1, p: 2 }}>
              <Grid item xs={12} md={6} lg={8}>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <Button
                    size="small"
                    onClick={() => setSlot(false)}
                    color={slot === false ? 'primary' : 'secondary'}
                    variant={slot === false ? 'outlined' : 'text'}
                    style={{ marginRight: 0 }}
                  >
                    Line
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setSlot(true)}
                    color={slot === true ? 'primary' : 'secondary'}
                    variant={slot === true ? 'outlined' : 'text'}
                    style={{ marginLeft: 0 }}
                  >
                    Area
                  </Button>
                </Stack>
             
                {slot ?
                  <div className="scroll-div">
                  <ManagerWiseTrends
                    title="Manager Wise Trends"
                    chartLabels={labels1}
                    chartData={lineGraphData}
                    chartColors={[
                      theme.palette.chart.red[0],
                      theme.palette.chart.green[3],
                      theme.palette.chart.yellow[0],
                      theme.palette.chart.pink[1],
                    ]}
                  />
                 </div>
                 :<div className="scroll-div">
                <ManagerWiseTrendsBarChart
                  title="Manager Wise Trends"
                  chartLabels={labels1}
                  chartData={areaGraphData}
                  chartColors={[
                      theme.palette.chart.red[0],
                      theme.palette.chart.green[3],
                      theme.palette.chart.yellow[0],
                      theme.palette.chart.pink[1],
                  ]}
                 />
                 </div>
                }
           
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardTitle className="card-content" tag="h3">
                  Total Notifications : {totalNotifications}
                </CardTitle>
                <OverallTrends
                  title="Overall Trends"
                  chartData={graphData}
                  chartColors={[
                    theme.palette.chart.red[0],
                    theme.palette.chart.green[3],
                    theme.palette.chart.yellow[0],
                    theme.palette.chart.pink[1],
                  ]}
                />
              </Grid>
            </Grid>
          </Container>
          <Modal.Footer>
            <Button
              color="danger"
              value="Delete Update"
              onClick={handleCloseGraph}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };
  //Summary
  const handleClickSummary = (e) => {
    console.log("event", e);
    setModalInfoSummary(e);
    var bl = toggleTrueFalseSummary();
    console.log("Set Modal info ", modalInfoSummary);
    console.log("togglevalue", bl);
    getSummary(e.notificationId);
  };

  const toggleTrueFalseSummary = () => {
    setShowSummaryModal(handleSummaryShow);
  };


    const handleClickGraph = (e) => {
      setModalInfoGraph(e);
      var bl = toggleTrueFalseGraph();
      getSummary(e.notificationId);
      getDashBoardMatric(e.notificationId)
    };

    const toggleTrueFalseGraph = () => {
      setShowGraphModal(handleShowGraphModal)
    };

  const ModalContentSummary = () => {
    return (
        <Modal
            {...props}
            size="lg"
            className="my-modal"
            aria-labelledby="contained-modal-title-vcenter"
            show={showSummaryModal}
            onHide={handleSummaryClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Row>
              <StickyTable height={200}>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-dark">
                  <tr>
                    <th scope="col">Total Notifications</th>
                    <th scope="col">Not Opened</th>
                    <th scope="col">Acknowledged</th>
                    <th scope="col">Training Required</th>
                    <th scope="col">Not Applicable</th>
                  </tr>
                  </thead>
                  <tbody className="pre-scrollable">
                  <tr>
                    <td>
                      {summary.counts && summary.counts.totalNotifications
                          ? summary.counts.totalNotifications
                          : "-"}
                    </td>
                    <td>
                      {summary.counts && summary.counts.unopened
                          ? summary.counts.unopened
                          : "-"}
                    </td>
                    <td>
                      {summary.counts && summary.counts.acknowledged
                          ? summary.counts.acknowledged
                          : "-"}
                    </td>
                    <td>
                      {summary.counts && summary.counts.trainingRequired
                          ? summary.counts.trainingRequired
                          : "-"}
                    </td>
                    <td>
                      {summary.counts && summary.counts.notApplicable
                          ? summary.counts.notApplicable
                          : "-"}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </StickyTable>
            </Row>
            <Row>
              <StickyTable>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-dark">
                  <tr>
                    <th scope="col">Not Opened</th>
                    <th scope="col">Acknowledged</th>
                    <th scope="col">Need Training</th>
                    <th scope="col">Marked Not Applicable</th>
                  </tr>
                  </thead>
                  <tbody className="pre-scrollable">
                  {tableData.map((row) => (
                      <tr>
                        <td>{row.didNotOpen}</td>
                        <td>{row.acknowledged}</td>
                        <td>{row.trainingRequired}</td>
                        <td>{row.notApplicable}</td>
                      </tr>
                  ))}
                  </tbody>
                </Table>
              </StickyTable>
            </Row>
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
    );
  };

  function extractPartialNotification(para) {
    let listOfLineBreak = para.split("\n");
    let partialNotification =
        listOfLineBreak[0] +
        "\n" +
        (listOfLineBreak[2] ? listOfLineBreak[2] : "");
    console.log("Extraction", partialNotification);
    return partialNotification;
  }

  const ExampleCustomInput = ({ value, onClick }) => (
      <button className="button-styling  btn" onClick={onClick}>
        {value}
      </button>
  );

  const handleCalendarClose = () => console.log("Calendar closed");
  const handleCalendarOpen = () => console.log("Calendar opened");

  const handleDateChange = (e) => {
    console.log("Date in HandleChange", e);
    const date = formatDate(e);
    console.log(" DF in fav: handleDate change", e, date, formatDate(e));
    console.log(" DF in fav: handleDate change Date", date);
    setDate(e);
  };

  const cancelSearch = () => {
    setSearch("");
  };

  const styleObj = {
    //     'margin-left': '-100px',
    "vertical-align": "top",
    "table-layout": "fixed",

    //     display: 'block',
  };

  return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3>Process Updates</h3>
                      {showReportModal ? <ModalContentReport /> : null}
                      {showGraphModal ? <ModalContentGraph /> : null}
                    </div>

                    <div className="button headerInline headerMargin textSizeHeader">
                      <button className="link-align disable-styling button-styling">
                        Month
                        <DatePicker
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            onChange={handleDateChange}
                            selected={date ? date : new Date()}
                            maxDate={new Date()}
                            minDate={new Date(2020, 0, 1)}
                            customInput={<ExampleCustomInput />}
                        ></DatePicker>
                      </button>
                    </div>
                    <div className="searchArea">
                      <SearchBar
                          className="search"
                          value={search}
                          placeholder="Search here"
                          onChange={(searchVal) => setSearch(searchVal)}
                          onCancelSearch={() => cancelSearch()}
                      />
                    </div>
                  </Row>
                </CardHeader>
                <StickyTable height={600}>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                      {/* <th scope="col">Id</th> */}
                      <th scope="col">Notification</th>
                      <th scope="col">Notified On</th>
                      <th scope="col">Notified To</th>
                      <th scope="col">
                        <Tooltip title="Report">
                          <button
                              style={{
                                color: "white",
                                "background-color": "Transparent",
                                "background-repeat": "no-repeat",
                                border: "none",
                                cursor: "pointer",
                                overflow: "hidden",
                                outline: "none",
                                padding: "0.3rem 0.3rem",
                                "overflow-x": "hidden",
                              }}
                              onClick={handleShowReportModal}
                          >
                            <i class="fa fa-download"></i>
                          </button>
                        </Tooltip>
                      </th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredNotifications.map((item) => {
                      return (
                          <tr>
                            {/* <td>{item.notificationId}</td> */}
                            <td
                                style={styleObj}
                                onClick={(e) => handleClick(item)}
                            >
                              {Number(item.date.split("-")[0]) < 2022 ||
                              item.notification.includes("\r\n") ? (
                                  extractPartialNotification(item.notification)
                              ) : (
                                  <div
                                      dangerouslySetInnerHTML={{
                                        __html: sanitize(
                                            item.notification.split("<br>")[0]
                                        ),
                                      }}
                                      className="ql-editor"
                                  ></div>
                              )}
                              {/* {item.notification} */}
                            </td>
                            <td>{item.date ? item.date.split(" ")[0] : null}</td>
                            <td>{item.teams}</td>
                          <td scope="col">
                            <Tooltip title="Summary">
                              <button
                                style={{
                                  color: "blue",
                                  "background-color": "Transparent",
                                  "background-repeat": "no-repeat",
                                  border: "none",
                                  cursor: "pointer",
                                  overflow: "hidden",
                                  outline: "none",
                                  padding: "0.3rem 0.3rem",
                                  "overflow-x": "hidden",
                                }}
                                onClick={(e) => handleClickSummary(item)}
                              >
                                <FontAwesomeIcon icon={faRectangleList} size="2x" style={{ color: "#1da1f2a8" }} />
                              </button>
                            </Tooltip>
                          </td>
                          <td scope="col">
                            <Tooltip title="Trends">
                              <button
                                style={{
                                  color: "blue",
                                  "background-color": "Transparent",
                                  "background-repeat": "no-repeat",
                                  border: "none",
                                  cursor: "pointer",
                                  overflow: "hidden",
                                  outline: "none",
                                  padding: "0.3rem 0.3rem",
                                  "overflow-x": "hidden",
                                }}
                                onClick={(e) => handleClickGraph(item)}
                              >
                                <FontAwesomeIcon icon={faChartColumn} size="2x" style={{ color: "#1da1f2a8" }} />
                              </button>
                            </Tooltip>
                          </td>
                          </tr>
                      );
                    })}
                    </tbody>
                  </Table>
                </StickyTable>
                {show ? <ModalContent /> : null}
                {showSummaryModal ? <ModalContentSummary /> : null}
              </Card>
            </div>
          </Row>
        </Container>
      </>
  );
};