import React, { useState, useEffect } from "react";
import Linkify from "react-linkify";

import { Modal } from "react-bootstrap";

// reactstrap components
import {} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StickyTable from "react-sticky-table-thead";
import UserId from "components/Auth/UserId";
import { sanitize } from "dompurify";
import SearchBar from "material-ui-search-bar";
import "../../src/assets/plugins/nucleo/css/Search.css";
import "./notificationStyles.css";
import UserSessionToken from "../components/Auth/UserSessionToken"

import { ThumbUp, ThumbDown } from "@material-ui/icons";
import swal2 from "sweetalert2";

import {
  Button,
  Table,
  Container,
  Row,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import axios from "axios";
import Header from "components/Headers/Header.js";
import swal from "sweetalert";

const Index = (props) => {
  console.log("PROPS in Index file", props);
  const [activeNav, setActiveNav] = useState(1);
  const [listOfNotification, setListOfNotification] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [modalInfo, setModalInfo] = useState([]);
  const [showModal, setShowModal] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(new Date());
  const [formatedDate, setFormatedDate] = useState("");

  const [enableThumbs, setEnableThumbs] = useState(true);

  const [openTime, setOpenTime] = useState();
  const [closeTime, setCloseTime] = useState();

  useEffect(() => {
    console.log("DF : formatted Date");
    getListOfNotification();
  }, [date]);

  function formatDate(date) {
    console.log("Date", date);
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  }

  const getListOfNotification = async () => {
    const currentUser = UserId();
    console.log("UserID in Associate Page", currentUser);
    console.log("Inside API formatted date: ", formatedDate);

    const result = await axios.get(
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "getAllUserNotificationDefaultView/" +
        currentUser +
        "/" +
        formatDate(date),{headers:{ Authorization: UserSessionToken()}}
    );
    let customData = [];

    setListOfNotification(result.data);
    console.log("List of User Notifications");
    console.log(result.data);
  };

  const [search, setSearch] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  useEffect(() => {
    console.log("listOfNoti", listOfNotification);
    setFilteredNotifications(
      listOfNotification.filter(
        (item, index) =>
          item.processUpdateStatusId.toString().indexOf(search.toLowerCase()) >
            -1 ||
          item.notification.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.date.toString().indexOf(search) > -1 ||
          (item.acknowledgedDate
            ? item.acknowledgedDate.toString()
            : ""
          ).indexOf(search) > -1 ||
          item.status.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    );
  }, [search, listOfNotification]);

  const handleClose = () => {
    let url =
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
      "updateUserActionAndReadTime/" +
      modalInfo.processUpdateStatusId +
      "/" +
      "readOnly";
    console.log("Associate --Resquest URL", url);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization : UserSessionToken(),
      },
      body: JSON.stringify({
        startTimeStamp: openTime,
        endTimeStamp: getFormattedDate(),
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Result", result);
        setApiResponse(result);
        setShow(false);
      })
      .catch(() => setShow(false));
  };

  const handleShow = () => setShow(true);

  const getFormattedDate = () => {
    let date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    var hour = ("0" + date.getHours()).slice(-2);
    var min = ("0" + date.getMinutes()).slice(-2);
    var seg = ("0" + date.getSeconds()).slice(-2);
    return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + seg;
  };

  const handleAcknowledge = (e) => {
    console.log(e.target.value);
    console.log(modalInfo.processUpdateStatusId);
    const status = e.target.value;
    const processUpdateStatusId = modalInfo.processUpdateStatusId;

    console.log("times : ", openTime, closeTime);

    if (status) {
      let url =
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "updateUserActionAndReadTime/" +
        processUpdateStatusId +
        "/" +
        status;
      console.log("Associate --Resquest URL", url);
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization : UserSessionToken(),
        },
        body: JSON.stringify({
          startTimeStamp: openTime,
          endTimeStamp: getFormattedDate(),
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("Result", result);
          setApiResponse(result);
          if (result.message === "Incorrect Signature") {
            swal("Error!!", "Something Wrong :)", "error");
          } else if (result.message === "SUCCESSFUL") {
            swal({
              // text: 'You have successfully selected : ' + status,
              text: "You have successfully updated the status",
              type: "success",
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL + "associates/mainpage";
              }
            });
          }
        });
    }
  };

  const handleThumbs = (e) => {
    console.log("thumbsClicked");

    const modalFeedback = modalInfo.feedback
      ? ", Feedback: " + modalInfo.feedback
      : "";

    if (!enableThumbs) {
      console.log("do nothing");
    } else {
      if (modalInfo.vote) {
        console.log("voteStatus", "alreadyVoted");
        swal2.fire({
          title: "Feedback already submitted",
          text: "Vote: " + modalInfo.vote + modalFeedback,
          icon: "success",
        });
      } else {
        console.log("voteStatus", "notVoted");
        handleClose();
        swal2
          .fire({
            title: "<h3>Have feedback on this update?</h3>",
            html: "Please provide more details",
            input: "text",
            inputPlaceholder:
              "Optional for ThumbsUp. Mandatory for ThumbsDown.",
            showCancelButton: true,
            confirmButtonText:
              '<i class="fa fa-thumbs-down"></i> Needs Improvement!',
            confirmButtonAriaLabel: "Thumbs down",
            cancelButtonText:
              '<i class="fa fa-thumbs-up thumbs-up-color"></i> Great!',
            cancelButtonAriaLabel: "Thumbs up, great!",
            inputValidator: (value) => {
              if (!value) {
                return "You need to write something!";
              }
            },
          })
          .then((result, value) => {
            console.log(result.value);
            if (result.isConfirmed) {
              handleFeedback(result.value, "thumbsDown");
            } else if (result.dismiss === swal2.DismissReason.cancel) {
              handleFeedback(result.value, "thumbsUp");
            } else if (
              result.dismiss === swal2.DismissReason.backdrop ||
              swal2.DismissReason.esc
            ) {
              handleShow();
            }
          });
      }
    }
  };

  const handleFeedback = (feedback, thumbs) => {
    fetch(
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "testerFeedback/" +
        modalInfo.processUpdateStatusId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: UserSessionToken()
        },
        body: JSON.stringify({
          vote: thumbs,
          feedback: feedback ? feedback : null,
        }),
      }
    );
    swal2.fire("Submitted!", "Your feedback has been submitted.", "success");
    handleShow();
    setEnableThumbs(false);
  };

  const getPageCount = () => {
    console.log("data", listOfNotification);
    console.log("pagesize", pageSize);
    setPageCount(Math.ceil(listOfNotification.length / pageSize));
    console.log("PageCount", pageCount);
  };

  useEffect(() => {
    getPageCount();
  }, []);

  const rowEvents = {
    onClick: (e) => {
      console.log("event", e);
    },
  };

  const handleClick = (e) => {
    setOpenTime(getFormattedDate);

    console.log("event", e);
    setModalInfo(e);
    var bl = toggleTrueFalse();
    console.log("Set Modal info ", modalInfo);
    console.log("togglevalue", bl);
  };

  const toggleTrueFalse = () => {
    setShowModal(handleShow);
  };

  const ModalContent = () => {
    return (
      <div class="parent" tabIndex="moreModal">
        <Modal
          {...props}
          size="lg"
          className="modal-fluid inner-parent"
          aria-labelledby="contained-modal-title-vcenter"
          show={show}
          autoFocus={false}
          // onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title>
              Process Update: {modalInfo.processUpdateStatusId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Linkify properties={{ target: "_blank" }}>
              {/* <div
              dangerouslySetInnerHTML={{
                __html: modalInfo.notification,
              }}
            /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(modalInfo.notification),
                }}
                className="ql-editor"
              ></div>
            </Linkify>
            <div class="bottom">
              <Button outline="true" onClick={handleThumbs}>
                {" "}
                Was this helpful?
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div hidden={modalInfo.status !== "Acknowledge"}>
              <Button
                color="primary"
                id="Acknowledge"
                value="Acknowledge"
                active={false}
                disabled
              >
                Acknowledged
              </Button>
            </div>
            <div hidden={modalInfo.status === "Acknowledge"}>
              <Button
                color="primary"
                id="Acknowledge"
                value="Acknowledge"
                onClick={handleAcknowledge}
              >
                Acknowledge
              </Button>
              <Button
                color="danger"
                id="TrainingRequired"
                value="TrainingRequired"
                onClick={handleAcknowledge}
              >
                Training Required
              </Button>
              <Button
                color="secondary"
                id="NotApplicable"
                value="NotApplicable"
                onClick={handleAcknowledge}
                hidden={
                  process.env.REACT_APP_TENANT === "PRIMEVIDEO"
                }              >
                Not Applicable
              </Button>
            </div>
            <Button
              color="secondary"
              onClick={handleClose}
              hidden={
                modalInfo.status === "" &&
                process.env.REACT_APP_TENANT !== "GCO"
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const handleFavorite = (e, item) => {
    console.log("event", e.target.value);
    console.log("ID", e, item.processUpdateStatusId);
    if (e.target.value) {
      fetch(
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
          "addFilterToProcessUpdate/" +
          item.processUpdateStatusId +
          "/" +
          e.target.value,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: UserSessionToken()
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result.details);
          setApiResponse(result.details);

          if (result.message === "SUCCESSFUL") {
            swal({
              text: "Notification is successfully added to your favourites.",
              type: "success",
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL + "associates/mainpage";
              }
            });
          } else {
            if (result.details) {
              swal({ text: result.details, icon: "error" });
            }
          }
        });
    }
  };

  const handleArchive = (e, item) => {
    console.log("event", e.target.value);
    console.log("ID", e, item.processUpdateStatusId);
    if (e.target.value) {
      fetch(
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
          "addFilterToProcessUpdate/" +
          item.processUpdateStatusId +
          "/" +
          e.target.value,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: UserSessionToken()
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result.details);
          setApiResponse(result.details);

          if (result.message === "SUCCESSFUL") {
            swal({
              text: "Notification is archived successfully",
              type: "success",
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL + "associates/mainpage";
              }
            });
          } else {
            if (result.details) {
              swal({ text: result.details, icon: "error" });
            }
          }
        });
    }
  };

  function extractPartialNotification(para) {
    //not used will be remove in next PR
    let listOfLineBreak = para.split("\n");
    let partialNotification =
      listOfLineBreak[0] +
      "\n" +
      (listOfLineBreak[2] ? listOfLineBreak[2] : "");
    return partialNotification;
  }

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="button-styling  btn" onClick={onClick}>
      {value}
    </button>
  );

  const handleDateChange = (e) => {
    const date = formatDate(e);
    console.log(" DF: handleDate change", e, date, formatedDate, formatDate(e));
    console.log(" DF: handleDate change Date", date);
    setDate(e);
  };

  const styleObj = {
    //     'margin-left': '-100px',
    "vertical-align": "top",
    "table-layout": "fixed",

    //     display: 'block',
  };

  const cancelSearch = () => {
    setSearch("");
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" tabindex="Card">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Process Updates</h3>
                  </div>

                  <div className="button headerInline headerMargin textSizeHeader">
                    <button className="link-align disable-styling button-styling">
                      Month
                      <DatePicker
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        onChange={handleDateChange}
                        selected={date ? date : new Date()}
                        maxDate={new Date()}
                        minDate={new Date(2020, 0, 1)}
                        customInput={<ExampleCustomInput />}
                      ></DatePicker>
                    </button>
                  </div>

                  <div className="searchArea">
                    <SearchBar
                      className="search"
                      value={search}
                      placeholder="Search here"
                      onChange={(searchVal) => setSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                  </div>
                </Row>
              </CardHeader>

              <StickyTable height={600}>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope="col">ID</th> */}
                      <th scope="col">Notification</th>
                      <th scope="col">Date Received</th>
                      <th scope="col">Date Of Acknowledgement</th>
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody align="justify">
                    {filteredNotifications.map((item) => (
                      <tr>
                        {/* <td>{item.processUpdateStatusId}</td> */}
                        <td style={styleObj} onClick={(e) => handleClick(item)}>
                          {Number(item.date.split("-")[0]) < 2022 ||
                          item.notification.includes("\r\n") ? (
                            extractPartialNotification(item.notification)
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitize(
                                  item.notification.split("<br>")[0]
                                ),
                              }}
                              className="ql-editor"
                            ></div>
                          )}
                          {/* {item.notification} */}
                        </td>
                        <td>{item.date ? item.date.split(" ")[0] : null}</td>
                        <td>
                          {item.acknowledgedDate
                            ? item.acknowledgedDate.split(" ")[0]
                            : null}
                        </td>
                        <td>
                          {item.status === "Acknowledge"
                            ? "Acknowledged"
                            : item.status}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i
                                className="fas fa-ellipsis-v"
                                style={{ color: "#1da1f2a8" }}
                              />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                value="favorite"
                                onClick={(e) => handleFavorite(e, item)}
                              >
                                Mark As Favorite
                              </DropdownItem>
                              <DropdownItem
                                value="Archive"
                                onClick={(e) => handleArchive(e, item)}
                              >
                                Mark As Archived
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </StickyTable>
              {show ? <ModalContent tabindex="ModalContent" /> : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Index;
