import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import axios from "axios";
import "../../assets/plugins/nucleo/css/styleNew.css";
import ReactQuill from "react-quill";
import "../../../node_modules/react-quill/dist/quill.snow.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Button,
  CardBody,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Form,
} from "reactstrap";

import { useTheme } from "@mui/material/styles";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import Header from "components/Headers/Header.js";
import UserSessionToken from "../../components/Auth/UserSessionToken";

const CreateNotificationPV = (props) => {
  const [gcoTeamAttributes, setGcoTeamAttributes] = useState(null);
  const [projects, setProjects] = useState([]);
  const [marketplaces, setMarketplaces] = useState([]);
  const [sites, setSites] = useState([]);
  const [projectMaps, setProjectMaps] = useState(new Map());
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [disableSendButton, setDisableSendButton] = useState(false);

  useEffect(() => {
    const fetchTeamAttributes = async () => {
      const result = await axios.get(
        process.env.REACT_APP_NOTIFIERV2_BACKEND + "getGCOTeamAttributes", {headers:{ Authorization: UserSessionToken()}}
      );
      console.log("fetched", result.data);

      if (result.data) {
        setGcoTeamAttributes(result.data);
      }
    };
    fetchTeamAttributes();
  }, []);

  useEffect(() => {
    const renderTeamDetails = () => {
      if (gcoTeamAttributes) {
        console.log("fetchedgcoTeamAttributes", gcoTeamAttributes);
        let projects = [];
        Object.keys(gcoTeamAttributes.projects).forEach((value) => {
          projects = projects.concat(value);
        });
        setProjects(projects);
        setMarketplaces(gcoTeamAttributes.marketPlaces);
        setSites(gcoTeamAttributes.sites);
        let newProjectMaps = new Map(
          Object.entries(gcoTeamAttributes.projects)
        );
        console.log("setProjectMaps", newProjectMaps);
        setProjectMaps(newProjectMaps);
      }
    };
    renderTeamDetails();
  }, [gcoTeamAttributes]);

  useEffect(() => {
    const hidePrograms = () => {
      projects.sort();
      setFilteredProjects(projects);
    };
    hidePrograms();
  }, [projects]);

  const [age, setAge] = React.useState("");
  const [rowSample, setRowRample] = useState({
    program: "",
    tasks: [],
    marketplaces: [],
    sites: [],
  });
  const [combinations, setCombinations] = useState([rowSample]);
  const handleProcessChange = (idx) => (event) => {};

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleTaskChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleCheckboxChange = (idx, checkbox, applicableList) => (e) => {
    console.log("checkboxIdx", idx, checkbox, e.target.checked);
    const tempCombinations = combinations;
    console.log("toUpateIdx", tempCombinations[idx]);

    if (e.target.checked) {
      tempCombinations[idx] = {
        ...tempCombinations[idx],
        [checkbox]: applicableList,
      };
    } else {
      tempCombinations[idx] = {
        ...tempCombinations[idx],
        [checkbox]: [],
      };
    }
    console.log("afterUpateIdx", tempCombinations[idx]);
    setCombinations(tempCombinations);
    handleAddRow(e);
    removeLastRow(e);
  };

  const handleTableChange = (idx) => (e) => {
    e.preventDefault();
    console.log("handleTableChangeIdx", idx, e.target.name, e.target.value);
    const { name, value } = e.target;
    const tempCombinations = combinations;

    tempCombinations[idx] = { ...tempCombinations[idx], [name]: value };

    if (name === "program") {
      tempCombinations[idx] = { ...tempCombinations[idx], tasks: [] };
    }

    console.log("tempcomb", tempCombinations);
    setCombinations(tempCombinations);
    handleAddRow(e);
    removeLastRow(e);
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    setCombinations([...combinations, rowSample]);
  };

  const handleRemoveSpecificRow = (index, event) => {
    event.preventDefault();
    setCombinations(combinations.filter((item, i) => i !== index));
  };

  const removeLastRow = (event) => {
    event.preventDefault();
    setCombinations(combinations.filter((item, i) => i !== -1));
  };

  const [notification, setNotification] = useState("");
  const [apiResponse, setApiResponse] = useState({});

  const [notificationBody, setNotificationBody] = useState("");
  const [listOfTeamNames, setListOfTeamNames] = useState({});
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState({});
  const [selectedAllCheckBoxes, setSelectedAllCheckBoxes] = useState({});

  const handleChangeProcessUpdate = (e) => {
    let processUpdateDetails = notification;
    processUpdateDetails = e;
    setNotification(processUpdateDetails, () => {
      console.log("Verify Notification", notification);
    });
  };

  //Counting net character --not in used -- not removed for future use
  function countNetCharecter(notificationEntered) {
    let enteredCharLength = notification.length;
    console.log("Total Character with style : ", enteredCharLength);
    let regex = /(<([^>]+)>)/gi;
    let resultNotification = notification
      .replace(regex, "")
      .replace(/&nbsp;/g, "");

    let filteredCharLength = resultNotification.length;
    let tagsCharLength = enteredCharLength - filteredCharLength;
    console.log("Total tags Character  : ", tagsCharLength);
    console.log("Total Net Character  : ", filteredCharLength);
    return filteredCharLength;
  }

  const onSaveRequest = () => {
    console.log("SelectedCheckBoxes", combinations);
    setDisableSendButton(true);

    const teamNames = Object.keys(selectedCheckBoxes).filter(
      (item) => selectedCheckBoxes[item]
    );
    console.log({ notification, teamNames });

    let notificationError = "";
    let selectedTeamError = "";
    let maxcharacterExceed = "";
    let invalidCombination = "";

    if (!notification || notification.length <= 0) {
      notificationError = "Error!! Please enter notification";
    } else if (!combinations || combinations.length <= 0) {
      selectedTeamError = "Error!! Please select applicable teams";
      console.log("Notification", notification);
    } else if (notification.length >= 6500) {
      maxcharacterExceed =
        "The character limit exceeded 6500: The total number of characters in the process update with styling is " +
        notification.length;
    }

    combinations.forEach((combination) => {
      if (
        combination.marketplaces.length < 1 ||
        combination.program === "" ||
        combination.tasks.length < 1 ||
        combination.sites.length < 1
      ) {
        invalidCombination = "Invalid applicable teams combination selected";
      }
    });

    if (
      notificationError ||
      selectedTeamError ||
      maxcharacterExceed ||
      invalidCombination
    ) {
      if (notificationError) {
        setDisableSendButton(false);
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: notificationError,
        });
      } else if (selectedTeamError) {
        setDisableSendButton(false);
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: selectedTeamError,
        });
      } else if (maxcharacterExceed) {
        setDisableSendButton(false);
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: maxcharacterExceed,
        });
      } else if (invalidCombination) {
        setDisableSendButton(false);
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: invalidCombination,
        });
      }
    } else {
      let body = { notification, combinations };
      console.log("body", body);
      console.log("Body JSON", JSON.stringify(body));
      swal2
        .fire({
          title: "Are you sure?",
          text: "This will send process update to selected teams !!!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Add!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            swal2.fire({
              title: "Notifying",
              text: "Please wait, sending email notifications to applicable team members !!!",
              icon: "warning",
              didOpen: () => {
                swal2.showLoading();
              },
            });
            fetch(
              process.env.REACT_APP_NOTIFIERV2_BACKEND +
                `sendNewGCOProcessUpdate`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  Authorization: UserSessionToken(),
                },
                body: JSON.stringify(body),
              }
            )
              .then((response) => response.json())
              .then((result) => {
                swal2.close();
                console.log(result);
                setApiResponse(result);
                if (notification === "") {
                  setDisableSendButton(false);
                  swal("Error!!", "Please enter process update :)", "error");
                } else if (result.message === "SUCCESSFUL") {
                  swal({
                    text: result.details,
                    type: "success",
                  }).then((okay) => {
                    if (okay) {
                      window.location.href =
                        process.env.REACT_APP_REDIRECT_URL +
                        "admin/send-notification";
                    }
                  });
                } else {
                  if (result.details) {
                    setDisableSendButton(false);
                    swal({
                      text: result.details,
                      icon: "error",
                    });
                  }
                }
              });
          } else if (result.dismiss) {
            setDisableSendButton(false);
          }
        });
    }
  };

  function unCheck() {
    var x = document.getElementsByClassName("checkboxSpacing");
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false;
    }
  }

  const onClearRequest = () => {
    console.log(
      "Clear",
      notification,
      selectedCheckBoxes,
      selectedAllCheckBoxes
    );
    setNotification("");
    let emptyList = [];
    unCheck();
    setSelectedAllCheckBoxes(emptyList);
    setSelectedCheckBoxes(emptyList);
  };

  const handleBody = (e) => {
    console.log("Notification Body", e);
    setNotificationBody(e);
  };

  CreateNotificationPV.modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { header: [3, 4, 5, 6] },
        { font: [] },
      ],
      //       [{ color: [] }, { background: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],

      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],

      ["link"],
      ["clean"],
    ],
  };
  CreateNotificationPV.formats = [
    "header",
    "size",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "indent",
    "clean",
    //     'color',
    //     'background',
  ];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Row>
          <Col className='order-xl-1'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Add New Update - Prime Video</h3>
                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Button
                    disabled={disableSendButton}
                    size='sm'
                    color='primary'
                    float='left'
                    onClick={onSaveRequest}
                  >
                    Send
                  </Button>
                  <Button size='sm' color='primary' onClick={onClearRequest}>
                    Clear
                  </Button>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className='pl-lg-4'>
                    <ReactQuill
                      placeholder='Add Process Update...'
                      modules={CreateNotificationPV.modules}
                      formats={CreateNotificationPV.formats}
                      onChange={handleChangeProcessUpdate}
                      value={notification}
                    />
                  </div>
                  <div className='Add'>
                    <div>
                      <h2 className='teamSelection'>
                        Select Applicable Team(s)
                      </h2>
                      <div className='container'>
                        <div className='row clearfix'>
                          <div className='col-md-12 column'>
                            <table
                              className='table table-bordered table-hover'
                              id='tab_logic'
                            >
                              <thead>
                                <tr>
                                  <th className='text-center'> Program </th>
                                  <th className='text-center'> Categories </th>
                                  <th className='text-center'> Territories </th>
                                  <th className='text-center'> Sites </th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {combinations.map((item, idx) => (
                                  <tr id='addr0' key={idx}>
                                    <td>
                                      <FormControl sx={{ m: 0, width: 250 }}>
                                        <InputLabel id='demo-simple-select-label'>
                                          Program
                                        </InputLabel>
                                        <Select
                                          labelId='demo-simple-select-label'
                                          id='demo-simple-select'
                                          value={
                                            combinations[idx].program?.[""]
                                          }
                                          label='Select Program'
                                          onChange={handleTableChange(idx)}
                                          name='program'
                                        >
                                          {filteredProjects.map((name) => (
                                            <MenuItem
                                              key={name}
                                              value={name}
                                              style={getStyles(
                                                name,
                                                personName,
                                                theme
                                              )}
                                            >
                                              {name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </td>
                                    <td>
                                      <FormControl sx={{ m: 0, width: 250 }}>
                                        <InputLabel id='demo-multiple-chip-label'>
                                          Tasks
                                        </InputLabel>
                                        <Select
                                          labelId='demo-multiple-chip-label'
                                          id='demo-multiple-chip'
                                          multiple
                                          value={combinations[idx].tasks}
                                          onChange={handleTableChange(idx)}
                                          input={
                                            <OutlinedInput
                                              id='select-multiple-chip'
                                              label='Chip'
                                            />
                                          }
                                          renderValue={(selected) => (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: 0.5,
                                              }}
                                            >
                                              {selected.map((value) => (
                                                <Chip
                                                  key={value}
                                                  label={value}
                                                />
                                              ))}
                                            </Box>
                                          )}
                                          MenuProps={MenuProps}
                                          name='tasks'
                                        >
                                          {projectMaps &&
                                          combinations[idx].program ? (
                                            projectMaps
                                              .get(combinations[idx].program)
                                              .map((name) => (
                                                <MenuItem
                                                  key={name}
                                                  value={name}
                                                  style={getStyles(
                                                    name,
                                                    personName,
                                                    theme
                                                  )}
                                                >
                                                  {name}
                                                </MenuItem>
                                              ))
                                          ) : (
                                            <MenuItem key={"Empty"}>
                                              Select Program
                                            </MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                    </td>
                                    <td>
                                      <FormControl sx={{ m: 0, width: 135 }}>
                                        <InputLabel id='demo-multiple-chip-label'>
                                          MPs
                                        </InputLabel>
                                        <Select
                                          labelId='demo-multiple-chip-label'
                                          id='demo-multiple-chip'
                                          multiple
                                          value={
                                            combinations[idx].marketplaces ?? []
                                          }
                                          onChange={handleTableChange(idx)}
                                          input={
                                            <OutlinedInput
                                              id='select-multiple-chip'
                                              label='Chip'
                                            />
                                          }
                                          renderValue={(selected) => (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: 0.5,
                                              }}
                                            >
                                              {selected.map((value) => (
                                                <Chip
                                                  key={value}
                                                  label={value}
                                                />
                                              ))}
                                            </Box>
                                          )}
                                          MenuProps={MenuProps}
                                          name='marketplaces'
                                        >
                                          {marketplaces.map((name) => (
                                            <MenuItem
                                              key={name}
                                              value={name}
                                              style={getStyles(
                                                name,
                                                personName,
                                                theme
                                              )}
                                            >
                                              {name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </td>
                                    <td>
                                      <FormControl sx={{ m: 0, width: 135 }}>
                                        <InputLabel id='demo-multiple-chip-label'>
                                          Sites
                                        </InputLabel>
                                        <Select
                                          labelId='demo-multiple-chip-label'
                                          id='demo-multiple-chip'
                                          multiple
                                          value={combinations[idx].sites ?? []}
                                          onChange={handleTableChange(idx)}
                                          input={
                                            <OutlinedInput
                                              id='select-multiple-chip'
                                              label='Chip'
                                            />
                                          }
                                          renderValue={(selected) => (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: 0.5,
                                              }}
                                            >
                                              {selected.map((value) => (
                                                <Chip
                                                  key={value}
                                                  label={value}
                                                />
                                              ))}
                                            </Box>
                                          )}
                                          MenuProps={MenuProps}
                                          name='sites'
                                        >
                                          {sites.map((name) => (
                                            <MenuItem
                                              key={name}
                                              value={name}
                                              style={getStyles(
                                                name,
                                                personName,
                                                theme
                                              )}
                                            >
                                              {name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </td>
                                    <td>
                                      <button
                                        className='btn btn-outline-danger btn-sm'
                                        onClick={(event) =>
                                          handleRemoveSpecificRow(idx, event)
                                        }
                                      >
                                        X
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <button
                              onClick={(event) => handleAddRow(event)}
                              className='btn btn-primary'
                            >
                              Add Row
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateNotificationPV;
