import React, { useState, useEffect } from "react";

import "../../assets/plugins/nucleo/css/style.css";
import "../../assets/plugins/nucleo/css/cardStyle.css";
import { Button, Table, Container, Row, Card } from "reactstrap";
import swal from "sweetalert";
import swal2 from "sweetalert2";

import Header from "components/Headers/Header.js";
import Select from "react-select";
import axios from "axios";
import StickyTable from "react-sticky-table-thead";
import UserSessionToken from "../../components/Auth/UserSessionToken";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const CustomeCard = () => {
  const [newTeam, setNewTeam] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [aliasName, setAliasName] = useState("");
  const [deleteAliasName, setDeleteAliasName] = useState("");
  const [updateAliasName, setUpdateAliasName] = useState("");
  const [listOfTeamNames, setListOfTeamNames] = useState([]);
  const [listOfDesignations, setListOfDesignations] = useState([]);
  const [listOfManagers, setListOfManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState([]);
  const [updateSelectedManager, setUpdateSelectedManager] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const [updateSelectedDesignation, setUpdateSelectedDesignation] = useState(
    []
  );
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedCombinations, setSelectedCombinations] = useState([])
  const [updateSelectedTeam, setUpdateSelectedTeam] = useState([]);
  const [allToolUsers, setAllToolUsers] = useState([]);

  useEffect(() => {
    getListOfOptions();
    getAllToolUsers();
  }, []);

  const getListOfOptions = async () => {
    const result = await axios.get(
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "getDistictRecordsFromUsersEntity", {headers:{ Authorization: UserSessionToken()}}
    );

    setListOfTeamNames(getFormatedData(result.data.teamNames));
    setListOfManagers(getFormatedData(result.data.managers));
    setListOfDesignations(getFormatedData(result.data.designations));
    console.log("List of teamnames ");
    console.log(result.data.teamNames);
  };

  let defaultGetUserDetails = ["ADECT"]
  const getAllToolUsers = async () => {
    const fetchDirectory = defaultGetUserDetails.includes(process.env.REACT_APP_TENANT)
      ? "getAllUserDetails"
      : "getAllGCOUserDetails";

    const result = await axios.get(
      process.env.REACT_APP_NOTIFIERV2_BACKEND + fetchDirectory, {headers:{ Authorization: UserSessionToken()}}
    );

    setAllToolUsers(result.data);
    console.log("List of All Tool Users ");
    console.log(result.data);
  };

  const getFormatedData = (options) => {
    let result = [];

    for (let i = 0; i < options.length; i++) {
      result.push({
        value: options[i],
        label: options[i],
      });
    }
    return result;
  };


    const generateCombinations = ()  => {
      console.log(selectedTeam);
      let tempCombinations = new Set();

      if(process.env.REACT_APP_TENANT==="APPSTORE"){
        selectedTeam.forEach((team) => {tempCombinations.add(team.value.replace("+BLR", ""))} )
      }else{
        selectedTeam.forEach((team) => {tempCombinations.add(team.value)} )
      }
            console.log(tempCombinations);
      return Array.from(tempCombinations);
    };



  const onAddUser = () => {
    console.log(
      "Test",
      aliasName,
      selectedManager,
      selectedTeam,
      selectedDesignation
    );
    let aliasError = "";
    let designationError = "";
    let managerError = "";
    let teamError = "";
    if (!aliasName || aliasName.length <= 0) {
      aliasError = "Error!! Please enter alias name";
    } else if (!selectedDesignation || selectedDesignation.length <= 0) {
      designationError = "Error!! Please select designation";
    } else if (!selectedManager || selectedManager.length <= 0) {
      managerError = "Error!! Please select manager";
    } else if (!selectedTeam || selectedTeam.length <= 0) {
      teamError = "Error!! Please select team";
    }

    console.log("Alias Name in add ", aliasName);
    console.log("designation in add ", selectedDesignation);
    console.log("managers in add ", selectedManager);
    console.log("teams in add", selectedTeam);

    if (aliasError || designationError || managerError || teamError) {
      if (aliasError) {
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: aliasError,
        });
      } else if (designationError) {
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: designationError,
        });
      } else if (managerError) {
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: managerError,
        });
      } else if (teamError) {
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: teamError,
        });
      }
    } else {
      let body = {
        name: aliasName,
        designation: selectedDesignation.value,
        manager: selectedManager.value,
        // team: selectedTeam.value ?? generateCombinations(),
        teams: selectedTeam.value ?? generateCombinations(),
      };
      console.log("Payload of add", body);

      swal2
        .fire({
          title: "Are you sure?",
          text: "This will add user!!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Add!",
        })
        .then((result) => {
          if (result.isConfirmed) {

            let asqsEligibleTenants = ["APPSTORE", "PRIMEVIDEO", "SELECTIONMONITORING"]

            let addEndpointSuffix = asqsEligibleTenants.includes(
              process.env.REACT_APP_TENANT) 
              ? `addOrUpdateASQSUserDetails` : `addNewUserDetails`

            fetch(
              process.env.REACT_APP_NOTIFIERV2_BACKEND + addEndpointSuffix,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  Authorization: UserSessionToken()
                },
                body: JSON.stringify(body),
              }
            )
              .then((response) => response.json())
              .then((result) => {
                console.log("result", result);
                setApiResponse(result.details);
                if (result.message === "SUCCESSFUL") {
                  swal({
                    text: "You have successfully added user",
                    type: "success",
                  }).then((okay) => {
                    if (okay) {
                      window.location.href =
                        process.env.REACT_APP_REDIRECT_URL +
                        "admin/notifications/self-service";
                    }
                  });
                } else {
                  if (result.details){
                    swal({ text: result.details, icon: "error" });
                  } else if (result.status != 200){
                    swal({ text: result.message, icon: "error" });
                  }
                }
              });
          } else {
            setAliasName("");
            setSelectedDesignation("");
            setSelectedManager("");
            setSelectedTeam("");
          }
        });
    }
  };

  const onUpdateUser = () => {
    console.log(
      "Test",
      updateAliasName,
      updateSelectedDesignation,
      updateSelectedManager,
      updateSelectedTeam
    );
    let aliasError = "";
    let designationError = "";
    let managerError = "";
    let teamError = "";
    if (!updateAliasName || updateAliasName.length <= 0) {
      aliasError = "Error!! Please enter alias name";
    } else if (
      !updateSelectedDesignation ||
      updateSelectedDesignation.length <= 0
    ) {
      designationError = "Error!! Please select designation";
    } else if (!updateSelectedManager || updateSelectedManager.length <= 0) {
      managerError = "Error!! Please select manager";
    } else if (!updateSelectedTeam || updateSelectedTeam.length <= 0) {
      teamError = "Error!! Please select team";
    }

    console.log("Alias Name in update", updateAliasName);
    console.log("designation in update", updateSelectedDesignation);
    console.log("managers in update", updateSelectedManager);
    console.log("teams in update ", updateSelectedTeam);

    if (aliasError || designationError || managerError || teamError) {
      if (aliasError) {
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: aliasError,
        });
      } else if (designationError) {
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: designationError,
        });
      } else if (managerError) {
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: managerError,
        });
      } else if (teamError) {
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: teamError,
        });
      }
    } else {
      let body = {
        name: updateAliasName,
        designation: updateSelectedDesignation.value,
        manager: updateSelectedManager.value,
        team: updateSelectedTeam.value,
      };
      console.log("Payload of update", body);

      swal2
        .fire({
          title: "Are you sure?",
          text: "This will update user details!!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Update!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            fetch(
              process.env.REACT_APP_NOTIFIERV2_BACKEND + `updateUserDetails`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  Authorization: UserSessionToken(),
                },
                body: JSON.stringify(body),
              }
            )
              .then((response) => response.json())
              .then((result) => {
                console.log("result of update", result.details);
                setApiResponse(result.details);
                if (result.message === "SUCCESSFUL") {
                  swal({
                    text: "You have successfully updated user details",
                    type: "success",
                  }).then((okay) => {
                    if (okay) {
                      window.location.href =
                        process.env.REACT_APP_REDIRECT_URL +
                        "admin/notifications/self-service";
                    }
                  });
                } else {
                  if (result.details)
                    swal({ text: result.details, icon: "error" });
                }
              });
          } else {
            setUpdateAliasName("");
            setUpdateSelectedDesignation("");
            setUpdateSelectedManager("");
            setUpdateSelectedTeam("");
          }
        });
    }
  };

  function splCharCheck(teamToAdd) {
    var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~0123456789]/;
    if (format.test(teamToAdd)) return true;
    else return false;
  }

  function endWithSpecialChar(teamToAdd) {
    var formatEnd = /[^[a-zA-Z0-9][a-zA-Z0-9_-_a-zA-Z0-9]*$]/;
    if (formatEnd.test(teamToAdd)) return true;
    else return false;
  }

  const onAddNewTeam = () => {
    let newTeamError = "";
    if (newTeam.length <= 0) {
      newTeamError = "Error!! Please enter team name";
    }
    if (splCharCheck(newTeam)) {
      newTeamError =
        'Error!!Team name with hyphen [-] is allowed with valid Prefix["BLR","SEA","JP","FR","AU","UK","DE","SJO","IT","ES"]';
    }
    if (endWithSpecialChar(newTeam)) {
      newTeamError = "Error!!Special character not allowed at end";
    }

    if (newTeamError) {
      swal2.fire({
        icon: "error",
        title: "Oops...",
        text: newTeamError,
      });
    } else {
      console.log(" listOfTeamNames options", listOfTeamNames);
      console.log("Managers", listOfManagers);
      console.log("User input new team name: ", newTeam);
      swal2
        .fire({
          title: "Are you sure?",
          text: "This will add new Team!!!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Add!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            fetch(
              process.env.REACT_APP_NOTIFIERV2_BACKEND +
                "addNewTeam/" +
                newTeam,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-type": "application/json",
                  Authorization: UserSessionToken(),
                },
              }
            )
              .then((response) => response.json())
              .then((result) => {
                console.log(result.details);
                setApiResponse(result.details);

                if (result.message === "SUCCESSFUL") {
                  swal({
                    text: "You have successfully added Configuration",
                    type: "success",
                  }).then((okay) => {
                    if (okay) {
                      window.location.href =
                        process.env.REACT_APP_REDIRECT_URL +
                        "admin/notifications/self-service";
                    }
                  });
                } else {
                  if (result.details) {
                    swal({ text: result.details, icon: "error" });
                  }
                }
              });
          } else {
            console.log("cancel");
            setNewTeam("");
          }
        });
    }
  };

  const onDeleteUser = () => {
    let aliasError = "";
    if (deleteAliasName.length <= 0) {
      aliasError = "Error!! Please enter alias name";
    }
    if (aliasError) {
      swal2.fire({
        icon: "error",
        title: "Oops...",
        text: aliasError,
      });
    } else {
      console.log("User need to be removed: ", deleteAliasName);
      swal2
        .fire({
          title: "Are you sure?",
          text: "This will delete the existing user!!!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            fetch(
              process.env.REACT_APP_NOTIFIERV2_BACKEND +
                "deleteUserAccess/" +
                deleteAliasName,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-type": "application/json",
                  Authorization: UserSessionToken()
                },
              }
            )
              .then((response) => response.json())
              .then((result) => {
                console.log("result", result);
                setApiResponse(result);
                if (
                  deleteAliasName.length <= 0 ||
                  result.message === "Incorrect Signature"
                ) {
                  swal(
                    "Error!!",
                    "Please enter the user alias name want to delete :)",
                    "error"
                  );
                } else if (result.message === "SUCCESSFUL") {
                  swal({
                    text: result.details,
                    result,
                    type: "success",
                  }).then((okay) => {
                    if (okay) {
                      window.location.href =
                        process.env.REACT_APP_REDIRECT_URL +
                        "admin/notifications/self-service";
                    }
                  });
                } else {
                  if (result.details)
                    swal({ text: result.details, icon: "error" });
                }
              });
          } else {
            setDeleteAliasName("");
          }
        });
    }
  };
  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 1 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <div
          id='cards_landscape_wrap-2'
          hidden={!process.env.REACT_APP_TENANT.includes("ADECT")}
        >
          <div className='row'>
            <div className='col-lg-5'>
              <form class='form-card'>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onAddUser}
                  >
                    ADD USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      id='field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9'
                      class='form-element-field'
                      placeholder='Please fill in your alias name'
                      type='input'
                      value={aliasName}
                      onChange={(e) => setAliasName(e.target.value)}
                      required
                    />

                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfDesignations'
                      styles={style}
                      isClearable={true}
                      value={selectedDesignation}
                      defaultValue={selectedDesignation}
                      options={listOfDesignations}
                      placeholder='Choose Designation.....'
                      onChange={(e) => {
                        setSelectedDesignation(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfManagers'
                      styles={style}
                      isClearable={true}
                      value={selectedManager}
                      defaultValue={selectedManager}
                      options={listOfManagers}
                      placeholder='Choose Manager....'
                      onChange={(e) => {
                        setSelectedManager(e);
                      }}
                    />

                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfTeamNames'
                      styles={style}
                      isClearable={true}
                      value={selectedTeam}
                      defaultValue={selectedTeam}
                      options={listOfTeamNames}
                      placeholder='Choose Team....'
                      onChange={(e) => {
                        setSelectedTeam(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                </fieldset>
              </form>
            </div>

            <div className='col-lg-5'>
              <form class='form-card'>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onUpdateUser}
                  >
                    UPDATE USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      id='field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9'
                      class='form-element-field'
                      placeholder='Please fill in your alias name'
                      type='input'
                      value={updateAliasName}
                      onChange={(e) => setUpdateAliasName(e.target.value)}
                      required
                    />
                    <div class='form-element-bar'></div>
                    <div class='form-element-bar'></div>
                  </div>

                  <div class='form-element form-select'>
                    <Select
                      id='listOfDesignations'
                      styles={style}
                      isClearable={true}
                      value={updateSelectedDesignation}
                      defaultValue={updateSelectedDesignation}
                      options={listOfDesignations}
                      placeholder='Choose Designation....'
                      onChange={(e) => {
                        setUpdateSelectedDesignation(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfManagers'
                      styles={style}
                      isClearable={true}
                      value={updateSelectedManager}
                      defaultValue={updateSelectedManager}
                      options={listOfManagers}
                      placeholder='Choose Manager....'
                      onChange={(e) => {
                        setUpdateSelectedManager(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfTeamNames'
                      styles={style}
                      isClearable={true}
                      value={updateSelectedTeam}
                      defaultValue={updateSelectedTeam}
                      options={listOfTeamNames}
                      placeholder='Choose Team....'
                      onChange={(e) => {
                        setUpdateSelectedTeam(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-5'>
              <form class='form-card'>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onAddNewTeam}
                  >
                    ADD TEAM
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      class='form-element-field'
                      type='name'
                      value={newTeam}
                      id='exampleInputEmail1'
                      aria-describedby='emailHelp'
                      placeholder='Enter Team Name..'
                      onChange={(e) => setNewTeam(e.target.value)}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                </fieldset>
              </form>
            </div>
            <div className='col-lg-5'>
              <form class='form-card'>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onDeleteUser}
                  >
                    DELETE USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      class='form-element-field'
                      type='name'
                      value={deleteAliasName}
                      id='exampleInputEmail1'
                      aria-describedby='emailHelp'
                      placeholder='Enter Alias..'
                      onChange={(e) => setDeleteAliasName(e.target.value)}
                    />

                    <div class='form-element-bar'></div>
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <div
          id='cards_landscape_wrap-2'
          hidden={!process.env.REACT_APP_TENANT.includes("PRIMEVIDEO")}
        >
         <div className='row'>
            <div className='col-lg-7 big-row' style={{ width: "md" }}>
              <form class='form-card' style={{ width: "md" }}>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onAddUser}
                  >
                    ADD / UPDATE USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      id='field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9'
                      class='form-element-field'
                      placeholder='Please fill in your alias name'
                      type='input'
                      value={aliasName}
                      onChange={(e) => setAliasName(e.target.value)}
                      required
                    />
                    <div class='form-element-bar'></div>
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfDesignations'
                      styles={style}
                      isClearable={true}
                      value={selectedDesignation}
                      defaultValue={selectedDesignation}
                      options={listOfDesignations}
                      placeholder='Choose Designation.....'
                      onChange={(e) => {
                        setSelectedDesignation(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfManagers'
                      styles={style}
                      isClearable={true}
                      value={selectedManager}
                      defaultValue={selectedManager}
                      options={listOfManagers}
                      placeholder='Choose Manager....'
                      onChange={(e) => {
                        setSelectedManager(e);
                      }}
                    />

                    <div class='form-element-bar'></div>
                  </div>
                  <div
                    class='form-element form-select'
                    style={{ width: "100" }}
                  >
                    <Select
                      id='listOfTeamNames'
                      isMulti
                      styles={style}
                      isClearable={true}
                      value={selectedTeam}
                      defaultValue={selectedTeam}
                      options={listOfTeamNames}
                      placeholder='Choose Combinations....'
                      onChange={(e) => {
                        setSelectedTeam(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div className='col-lg-5'>
              <form class='form-card'>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onDeleteUser}
                  >
                    DELETE USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      class='form-element-field'
                      type='name'
                      value={deleteAliasName}
                      id='exampleInputEmail1'
                      aria-describedby='emailHelp'
                      placeholder='Enter Alias..'
                      onChange={(e) => setDeleteAliasName(e.target.value)}
                    />

                    <div class='form-element-bar'></div>
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <div
          id='cards_landscape_wrap-2'
          hidden={!process.env.REACT_APP_TENANT.includes("SELECTIONMONITORING")}
        >
          <div className='row'>
            <div className='col-lg-7 big-row' style={{ width: "md" }}>
              <form class='form-card' style={{ width: "md" }}>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onAddUser}
                  >
                    ADD / UPDATE USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      id='field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9'
                      class='form-element-field'
                      placeholder='Please fill in your alias name'
                      type='input'
                      value={aliasName}
                      onChange={(e) => setAliasName(e.target.value)}
                      required
                    />
                    <div class='form-element-bar'></div>
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfDesignations'
                      styles={style}
                      isClearable={true}
                      value={selectedDesignation}
                      defaultValue={selectedDesignation}
                      options={listOfDesignations}
                      placeholder='Choose Designation.....'
                      onChange={(e) => {
                        setSelectedDesignation(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfManagers'
                      styles={style}
                      isClearable={true}
                      value={selectedManager}
                      defaultValue={selectedManager}
                      options={listOfManagers}
                      placeholder='Choose Manager....'
                      onChange={(e) => {
                        setSelectedManager(e);
                      }}
                    />

                    <div class='form-element-bar'></div>
                  </div>
                  <div
                    class='form-element form-select'
                    style={{ width: "100" }}
                  >
                    <Select
                      id='listOfTeamNames'
                      isMulti
                      styles={style}
                      isClearable={true}
                      value={selectedTeam}
                      defaultValue={selectedTeam}
                      options={listOfTeamNames}
                      placeholder='Choose Combinations....'
                      onChange={(e) => {
                        setSelectedTeam(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div className='col-lg-5'>
              <form class='form-card'>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onDeleteUser}
                  >
                    DELETE USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      class='form-element-field'
                      type='name'
                      value={deleteAliasName}
                      id='exampleInputEmail1'
                      aria-describedby='emailHelp'
                      placeholder='Enter Alias..'
                      onChange={(e) => setDeleteAliasName(e.target.value)}
                    />

                    <div class='form-element-bar'></div>
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <div
          id='cards_landscape_wrap-2'
          hidden={!process.env.REACT_APP_TENANT.includes("APPSTORE")}
        >
          <div className='row'>
            <div className='col-lg-7 big-row' style={{ width: "md" }}>
              <form class='form-card' style={{ width: "md" }}>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onAddUser}
                  >
                    ADD / UPDATE USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      id='field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9'
                      class='form-element-field'
                      placeholder='Please fill in your alias name'
                      type='input'
                      value={aliasName}
                      onChange={(e) => setAliasName(e.target.value)}
                      required
                    />
                    <div class='form-element-bar'></div>
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfDesignations'
                      styles={style}
                      isClearable={true}
                      value={selectedDesignation}
                      defaultValue={selectedDesignation}
                      options={listOfDesignations}
                      placeholder='Choose Designation.....'
                      onChange={(e) => {
                        setSelectedDesignation(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-select'>
                    <Select
                      id='listOfManagers'
                      styles={style}
                      isClearable={true}
                      value={selectedManager}
                      defaultValue={selectedManager}
                      options={listOfManagers}
                      placeholder='Choose Manager....'
                      onChange={(e) => {
                        setSelectedManager(e);
                      }}
                    />

                    <div class='form-element-bar'></div>
                  </div>
                  <div
                    class='form-element form-select'
                    style={{ width: "100" }}
                  >
                    <Select
                      id='listOfTeamNames'
                      isMulti
                      styles={style}
                      isClearable={true}
                      value={selectedTeam}
                      defaultValue={selectedTeam}
                      options={listOfTeamNames}
                      placeholder='Choose Combinations....'
                      onChange={(e) => {
                        setSelectedTeam(e);
                      }}
                    />
                    <div class='form-element-bar'></div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div className='col-lg-5'>
              <form class='form-card'>
                <fieldset class='form-fieldset'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={onDeleteUser}
                  >
                    DELETE USER
                  </Button>
                  <div class='form-element form-input'>
                    <input
                      class='form-element-field'
                      type='name'
                      value={deleteAliasName}
                      id='exampleInputEmail1'
                      aria-describedby='emailHelp'
                      placeholder='Enter Alias..'
                      onChange={(e) => setDeleteAliasName(e.target.value)}
                    />

                    <div class='form-element-bar'></div>
                    <div class='form-element-bar'></div>
                  </div>
                  <div class='form-element form-input form-has-error'></div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>

        <br></br>

        <Row>
          <div className='col'>
            <Card className='shadow'>
              <StickyTable height={600}>
                <Table className='align-items-center table-flush' responsive>
                  <thead className='thead-dark'>
                    <tr>
                      <th scope='col'>Name</th>
                      <th scope='col'>Team</th>
                      <th scope='col'>Designation</th>
                      <th scope='col'>Manager</th>
                    </tr>
                  </thead>
                  <tbody className='pre-scrollable'>
                    {allToolUsers.map((item) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.team}</td>
                          <td>{item.designation}</td>
                          <td>{item.manager}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </StickyTable>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CustomeCard;
