import Index from "views/Index.js";
import MainPageAdmin from "views/examples/MainPageAdmin";
import CreateNotification from "views/examples/CreateNotification";
import CreateNotificationGCO from "views/examples/CreateNotificationGCO";
import CreateNotificationASQS from "views/examples/CreateNotificationASQS";
import CreateNotificationPV from "views/examples/CreateNotificationPV";
import CreateNotificationSM from "views/examples/CreateNotificationSM";
import Favourite from "views/examples/Favourite";
import Archived from "views/examples/Archived";
import HomePage from "views/examples/HomePage";
import CustomeCard from "views/examples/Card";

var routes = [
  {
    path: "/home",
    name: "Home",
    icon: "ni ni-archive-2 text-primary",
    component: HomePage,
    layout: "/admin",
  },
  {
    path: "/mainpage",
    name: "Admin Notifications",
    icon: "ni ni-tv-2 text-primary",
    component: MainPageAdmin,
    layout: "/admin",
  },
  {
    path: "/send-notification",
    name: "Send New Update",
    icon: "ni ni-ruler-pencil text-primary",
    component: process.env.REACT_APP_TENANT.includes("GCO")
      ? CreateNotificationGCO
      : process.env.REACT_APP_TENANT.includes("APPSTORE")
      ? CreateNotificationASQS
      : process.env.REACT_APP_TENANT.includes("PRIMEVIDEO")
      ? CreateNotificationPV
      : process.env.REACT_APP_TENANT.includes("SELECTIONMONITORING")
      ? CreateNotificationSM
      : CreateNotification,
    layout: "/admin",
  },
  {
    path: "/notifications/self-service",
    name: "Admin Self Service",
    icon: "ni ni-archive-2 text-primary",
    component: CustomeCard,
    layout: "/admin",
  },
  {
    path: "/mainpage",
    name: "My Notifications",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/associates",
  },
  {
    path: "/notifications/favourite",
    name: "My Favourites",
    icon: "ni ni-favourite-28 text-primary",
    component: Favourite,
    layout: "/associates",
  },
  {
    path: "/notifications/archived",
    name: "Archived Notifications",
    icon: "ni ni-archive-2 text-primary",
    component: Archived,
    layout: "/associates",
  },
];

export default routes;
